import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import { Link, graphql, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"

const Menu = props => {
  return (
    <Layout>
      <Helmet>
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>
      <Navbar />
      <PageBanner bgImageClass="our-menu-bg" pageTitle="Our Menu" />
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="" id="Mobi2Go-Storefront"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default Menu

export const MenuQuery = graphql`
  query {
    allPricelistXlsxPizzaAndIngredients {
      edges {
        node {
          id
          Category
          Description
          Title
          large
          medium
          small
        }
      }
    }
    allPricelistXlsxMealDeals {
      edges {
        node {
          Category
          Description
          Title
          small
        }
      }
    }
    allPricelistXlsxSides {
      edges {
        node {
          Category
          Title
          small
        }
      }
    }
  }
`
